export const nav = {
    'NAV': {
        'OPERACION': 'Operación',
        'DASHBOARD': 'Dashboard',
        'AGENDA': 'Agenda',
        'EGRESOS': 'Egresos',
        'VENTA_RADIOLOGA': 'Venta Radiologa',
        'CARGA_ESTUDIOS': 'Carga de Estudios',
        'VENTA_LABORATORIO': 'Venta Laboratorio',
        'PAGOS': 'Pagos',
        'REPORTE_VENTAS': 'Reporte de Ventas',
        'INVENTARIO': 'Inventario',
        'BUSINESS_INTELLIGENCE': 'BUSINESS INTELLIGENCE (BI)',
        'BUSINESS_INTELLIGENCE_MENU_1': 'Business Intelligence',
        'CATALOGOS-MENU': 'Catálogos',
        'CATALOGO-MENU-SUCURSALES': 'Sucursales',
        'CATALOGO-MENU-DOCTORES': 'Doctores',
        'CATALOGO-MENU-SERVICIOS-LABORATORIO': 'Servicios de Laboratorio',
        'CATALOGO-MENU-SERVICIOS-RADIOLOGIA': 'Servicios de Radiología',
        'MENU-CONFIGURACION': 'Configuración',
        'MENU-CONFIGURACION-ROLES': 'Roles'
    }
}