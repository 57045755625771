import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

@Component({
  selector: 'app-stl-viewer-modal',
  templateUrl: './stl-viewer-modal.component.html',
  styleUrls: ['./stl-viewer-modal.component.css']
})
export class StlViewerModalComponent implements OnInit, AfterViewInit {
  title: string;
  file: File;
  closeBtnName: string;
  @ViewChild('rendererContainer') rendererContainer: ElementRef;
  pdfSrc: any;
  imageSrc: string;
  isPDF: boolean = false;
  isImage: boolean = false;
  isSTL: boolean = false;

  camera: THREE.PerspectiveCamera;
  controls: OrbitControls;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.file) {
      const fileType = this.file.type;
      console.log('Detected file type:', fileType);

      if (fileType === 'application/pdf') {
        this.isPDF = true;
        this.isImage = false;
        this.isSTL = false;
        this.loadPDF(this.file);
      } else if (fileType.startsWith('image/')) {
        this.isPDF = false;
        this.isImage = true;
        this.isSTL = false;
        this.loadImage(this.file);
      } else if (fileType === 'model/stl' || this.file.name.endsWith('.stl')) {
        this.isPDF = false;
        this.isImage = false;
        this.isSTL = true;
        this.loadSTL(this.file);
      } else {
        console.error('Unsupported file type:', fileType);
      }
    }
  }

  loadSTL(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const contents = e.target.result;
      const scene = new THREE.Scene();

      if (this.rendererContainer && this.rendererContainer.nativeElement) {
        this.camera = new THREE.PerspectiveCamera(75, this.rendererContainer.nativeElement.clientWidth / this.rendererContainer.nativeElement.clientHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(this.rendererContainer.nativeElement.clientWidth, this.rendererContainer.nativeElement.clientHeight);
        this.rendererContainer.nativeElement.innerHTML = '';
        this.rendererContainer.nativeElement.appendChild(renderer.domElement);

        this.controls = new OrbitControls(this.camera, renderer.domElement);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.25;
        this.controls.screenSpacePanning = false;
        this.controls.maxPolarAngle = Math.PI;
        this.controls.minPolarAngle = 0;

        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight.position.set(5, 5, 5).normalize();
        scene.add(directionalLight);

        const loader = new STLLoader();
        const geometry = loader.parse(contents);
        const material = new THREE.MeshPhongMaterial({ flatShading: true });
        const mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);

        const center = new THREE.Vector3();
        geometry.computeBoundingBox();
        geometry.boundingBox.getCenter(center);
        mesh.position.sub(center);

        const boundingBox = geometry.boundingBox;
        const size = new THREE.Vector3();
        boundingBox.getSize(size);
        const maxDim = Math.max(size.x, size.y, size.z);
        const fov = this.camera.fov * (Math.PI / 180);
        const cameraZ = (maxDim / 2) / Math.tan(fov / 2);

        this.camera.position.set(center.x, center.y, cameraZ * 1.2);

        const minZ = boundingBox.min.z;
        const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

        this.camera.far = cameraToFarEdge * 3;
        this.camera.updateProjectionMatrix();

        this.controls.update();

        const animate = () => {
          requestAnimationFrame(animate);
          this.controls.update();
          renderer.render(scene, this.camera);
        };
        animate();
      }
    };
    reader.readAsArrayBuffer(file);
  }

  loadImage(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageSrc = e.target.result;
      if (this.rendererContainer && this.rendererContainer.nativeElement) {
        this.rendererContainer.nativeElement.innerHTML = '';
      }
    };
    reader.readAsDataURL(file);
  }

  loadPDF(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.pdfSrc = new Uint8Array(e.target.result);
      if (this.rendererContainer && this.rendererContainer.nativeElement) {
        this.rendererContainer.nativeElement.innerHTML = '';
      }
    };
    reader.readAsArrayBuffer(file);
  }

  zoomIn(): void {
    if (this.camera) {
      this.camera.position.z -= 10;
      this.camera.updateProjectionMatrix();
    }
  }

  zoomOut(): void {
    if (this.camera) {
      this.camera.position.z += 10;
      this.camera.updateProjectionMatrix();
    }
  }
}
