import { Component, ElementRef, ViewChild, OnInit, ChangeDetectorRef, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpResponse } from '@angular/common/http';
import { UploadService } from './upload.service';
import { UploadFile, Lifecycle, CommonFile, UploadResponse, ListType } from './upload.interface';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { StlViewerModalComponent } from '../stl-viewer-modal/stl-viewer-modal.component';

@Component({
  selector: 'upload',
  templateUrl: 'upload.component.html',
  styleUrls: [ 'upload.component.css' ]
})
export class UploadComponent implements OnInit {
  @ViewChild('input') input: ElementRef;

  @Input() disabled: boolean = false;
  @Input() data: any = {};
  @Input() name: string = 'file';
  @Input() action: string;
  @Input() accept: string;
  @Input() drag: boolean = false;
  @Input() multiple: boolean = true;
  @Input() headers?: any = {};
  @Input() avatar: boolean = false;
  @Input() imageUrl: SafeUrl;

  @Input() withCredentials: boolean = false;
  @Input() showFileList: boolean = false; //para ver la lista de archivos
  @Input() listType: ListType = 'text';
  @Input() fileList: UploadFile[] = [];

  @Output() preview: EventEmitter<CommonFile> = new EventEmitter<CommonFile>();
  @Output() remove: EventEmitter<CommonFile> = new EventEmitter<CommonFile>();
  @Output() progress: EventEmitter<UploadResponse<any>> = new EventEmitter<UploadResponse<any>>();

  @Output() success: EventEmitter<UploadResponse<any>> = new EventEmitter<UploadResponse<any>>();
  @Output() error: EventEmitter<UploadResponse<any>> = new EventEmitter<UploadResponse<any>>();

  @Input() uploadInterceptor: (f: File) => boolean = f => true;

  files: CommonFile[] = [];
  bsModalRef: BsModalRef;

  static generateID(): string {
    return Math.random().toString(16).substr(2, 8);
  }
  static updatePercentage(response: any): number {
    const { loaded, total } = response;
    if (loaded === undefined || !total) return 0;
    return Math.round(loaded / total * 100);
  }

  constructor(
    private uploadSvc: UploadService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService
  ) {}

  clickHandle(): void {
    if (this.disabled) return;
    this.input.nativeElement.click();
  }

  changeHandle(event: Event): void {
    const files: FileList = (<HTMLInputElement>event.target).files;
    if (!files || !files.length) return;
    const checkedFiles: File[] = this.multiple ? Array.from(files) : [files[0]];
    this.input.nativeElement.value = null;
    checkedFiles.forEach((file: File) => {
      const next = {
        id: UploadComponent.generateID(),
        name: file.name,
        status: 'ready',
        size: file.size,
        percentage: 0,
        raw: file,
        url: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
      };
      this.files.push(next);
      this.updateFile(next);
    });
  }

  upload(file: CommonFile): void {
    file.status = 'ready';
    this.updateFile(file);
    setTimeout(() => {
      file.status = 'success';
      file.percentage = 100;
      this.updateFile(file);
      this.lifecycle.success(file, new HttpResponse({ status: 200 }));
    }, 1000);
  }

  removeHandle(file: CommonFile): void {
    this.lifecycle.remove(file);
    const index = this.files.findIndex(({ id }) => file.id === id);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.updateFileList();
    }
  }

  updateFileList(): void {
    this.cdr.detectChanges();
  }

  updateFile(file: CommonFile): void {
    const index = this.files.findIndex(({ id }) => file.id === id);
    if (index !== -1) {
      this.files[index] = file;
      this.cdr.detectChanges();
    }
  }

  openModal(file: File): void {
    const initialState = {
      title: 'Estudio',
      file: file,
      closeBtnName: 'Cerrar'
    };
    this.bsModalRef = this.modalService.show(StlViewerModalComponent, { initialState, class: 'modal-dialog-centered modal-xl' });
  }

  ngOnInit(): void {
    this.uploadSvc
      .setHeader(this.headers)
      .setCredentials(this.withCredentials)
      .setFileName(this.name)
      .addExtraData(this.data);
    this.fileList.forEach((file: UploadFile) => {
      this.files.push({
        id: UploadComponent.generateID(),
        name: file.name,
        status: 'success',
        raw: null,
        size: null,
        url: this.sanitizer.bypassSecurityTrustUrl(file.url),
      });
    });
  }

  protected get lifecycle(): Lifecycle {
    return {
      preview: (f: CommonFile) => this.preview.emit(f),
      remove: (f: CommonFile) => this.remove.emit(f),
      success: (f: CommonFile, res: HttpResponse<any>) => this.success.emit({ commonFile: f, response: res }),
      error: (f: CommonFile, err: any) => this.error.emit({ commonFile: f, error: err }),
      progress: (f: CommonFile, percentage: number) => this.progress.emit({ commonFile: f, percentage }),
    };
  }
}
