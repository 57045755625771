import { Routes } from '@angular/router';

export const APP_LAYOUT_ROUTES: Routes = [
    //Dashboard
    {
        path: 'dashboard',
        loadChildren: () => import('../views/dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: 'servicios-radiologia',
        loadChildren: () => import('../views/servicios-radiologia/servicios-radiologia.module').then(m => m.ServiciosRadiologiaModule),
    },
    {
        path: 'servicios-laboratorio',
        loadChildren: () => import('../views/servicios-laboratorio/servicios-laboratorio.module').then(m => m.ServiciosLaboratorioModule)
    },
    {
        path: 'doctores',
        loadChildren: () => import('../views/doctores/doctores.module').then(m => m.DoctoresModule)
    },
    {
        path: 'sucursales',
        loadChildren: () => import('../views/sucursales/sucursales.module').then(m => m.SucursalesModule)
    },
    {
        path: 'venta-radiologia',
        loadChildren: () => import('../views/venta-radiologia/venta-radiologia.module').then(m => m.VentaRadiologiaModule)
    }
];