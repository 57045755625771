import { NavMenu } from '@app/shared/types/nav-menu.interface';

const menuOperacion: NavMenu[] = [
    {
        path: '',
        title: 'Operación',
        translateKey: 'NAV.OPERACION',
        type: 'title',
        iconType: 'feather',
        icon: 'icon-users',
        key: 'catalogos-menu',
        submenu: [
            {
                path: '/dashboard',
                title: 'Dashboard',
                translateKey: 'NAV.DASHBOARD',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-home',
                key: 'dashboard',
                submenu: []
            },
            {
                path: '/agenda',
                title: 'Agenda',
                translateKey: 'NAV.AGENDA',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-calendar',
                key: 'ageda',
                submenu: []
            },
            {
                path: '/egresos',
                title: 'Egresos',
                translateKey: 'NAV.EGRESOS',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-arrow-up-right',
                key: 'egresos',
                submenu: []
            },
            {
                path: '/venta-radiologia',
                title: 'Venta Radiología',
                translateKey: 'NAV.VENTA_RADIOLOGA',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-align-center',
                key: 'venta-radiologia',
                submenu: []
            },
            {
                path: '/carga-estudios',
                title: 'Carga de Estudios',
                translateKey: 'NAV.CARGA_ESTUDIOS',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-upload',
                key: 'carga-estudios',
                submenu: []
            },
            {
                path: '/venta-laboratorio',
                title: 'Venta Laboratorio',
                translateKey: 'NAV.VENTA_LABORATORIO',
                type: 'item',
                iconType: 'line-awesome',
                icon: 'la-flask',
                key: 'venta-laboratorio',
                submenu: []
            },
            {
                path: '/pagos',
                title: 'Pagos',
                translateKey: 'NAV.PAGOS',
                type: 'item',
                iconType: 'line-awesome',
                icon: 'la-money-bill',
                key: 'pagos',
                submenu: []
            },
            {
                path: '/reporte-ventas',
                title: 'Reporte de Ventas',
                translateKey: 'NAV.REPORTE_VENTAS',
                type: 'item',
                iconType: 'line-awesome',
                icon: 'la-clipboard-list',
                key: 'pagos',
                submenu: []
            },
            {
                path: '/inventario',
                title: 'Inventario',
                translateKey: 'NAV.INVENTARIO',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-package',
                key: 'pagos',
                submenu: []
            }
        ]
    }
]

const menuBussinesIntelligence: NavMenu[] = [
    {
        path: '',
        title: 'BUSINESS INTELLIGENCE (BI)',
        translateKey: 'NAV.BUSINESS_INTELLIGENCE',
        type: 'title',
        iconType: 'feather',
        icon: 'icon-pie-chart',
        key: 'business-intelligence-menu',
        submenu: [
            {
                path: '/business-intelligence',
                title: 'Business Intelligence',
                translateKey: 'NAV.BUSINESS_INTELLIGENCE_MENU_1',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-trending-up',
                key: 'business-intelligence-menu.business-intelligence',
                submenu: []
            }
        ]
    }
]

const menuCatalogos: NavMenu[] = [
    {
        path: '',
        title: 'Catálogos',
        translateKey: 'NAV.CATALOGOS-MENU',
        type: 'title',
        iconType: 'feather',
        icon: 'icon-clipboard',
        key: 'catalogos-menu',
        submenu: [
            {
                path: '/sucursales',
                title: 'Sucursales',
                translateKey: 'NAV.CATALOGO-MENU-SUCURSALES',
                type: 'item',
                iconType: 'line-awesome',
                icon: 'la-building',
                key: 'catalogos-menu.sucursales',
                submenu: []
            },
            {
                path: '/doctores',
                title: 'Doctores',
                translateKey: 'NAV.CATALOGO-MENU-DOCTORES',
                type: 'item',
                iconType: 'line-awesome',
                icon: 'la-user-nurse',
                key: 'catalogos-menu.doctores',
                submenu: []
            },
            {
                path: '/servicios-laboratorio',
                title: 'Servicios de Laboratorio',
                translateKey: 'NAV.CATALOGO-MENU-SERVICIOS-LABORATORIO',
                type: 'item',
                iconType: 'line-awesome',
                icon: 'la-id-card-alt',
                key: 'catalogos-menu.servicios-laboratorio',
                submenu: []
            },
            {
                path: '/servicios-radiologia',
                title: 'Servicios de Radiología',
                translateKey: 'NAV.CATALOGO-MENU-SERVICIOS-RADIOLOGIA',
                type: 'item',
                iconType: 'line-awesome',
                icon: 'la-x-ray',
                key: 'catalogos-menu.servicios-radilogia',
                submenu: []
            },
        ]
    }
]

const menuConfiguracion: NavMenu[] = [
    {
        path: '',
        title: 'Configuración',
        translateKey: 'NAV.MENU-CONFIGURACION',
        type: 'title',
        iconType: 'feather',
        icon: 'icon-settings',
        key: 'configuracion-menu',
        submenu: [
            {
                path: '/roles',
                title: 'Roles',
                translateKey: 'NAV.MENU-CONFIGURACION-ROLES',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-settings',
                key: 'configuracion-menu.roles',
                submenu: []
            }
        ]
    }
]

export const navConfiguration: NavMenu[] = [
    ...menuOperacion,
    ...menuBussinesIntelligence,
    ...menuCatalogos,
    ...menuConfiguracion
]