import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { StlViewerModalComponent } from './components/stl-viewer-modal/stl-viewer-modal.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
  declarations: [
    StlViewerModalComponent
  ],
  imports: [
    CommonModule, // Importa CommonModule aquí
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule,
    NgxExtendedPdfViewerModule
  ],
  exports: [
    StlViewerModalComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule,
    NgxExtendedPdfViewerModule
  ]
})
export class SharedModule { }
