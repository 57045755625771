<ng-template #uploadList>
  <upload-list [files]="files" *ngIf="showFileList" [list-type]="listType" [disabled]="disabled"
    (remove)="removeHandle($event)" (preview)="lifecycle.preview($event)">
  </upload-list>
</ng-template>

<ng-template #avatarFrame>
  <div class="upload-avatar" [class.is-disabled]="disabled">
    <img *ngIf="imageUrl" [src]="imageUrl" />
    <i *ngIf="!imageUrl" class="feather icon-plus avatar-uploader-icon"></i>
  </div>
</ng-template>

<ng-template #triggerBlock>
  <div [class]="'upload upload-' + listType" (click)="clickHandle()" [class.is-disabled]="disabled">
    <div class="btn btn-primary" [class.disabled]="disabled" *ngIf="!trigger.innerHTML.trim() && !avatar">
      Click to upload
    </div>
    <div #trigger>
      <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!trigger.innerHTML.trim() && avatar">
      <ng-template [ngTemplateOutlet]="avatarFrame"></ng-template>
    </ng-container>
    <input [accept]="accept" class="upload-input" type="file" name="file" #input [name]="name" [disabled]="disabled"
      [multiple]="multiple" (change)="changeHandle($event)">
  </div>
</ng-template>

<upload-dragger *ngIf="drag" [disabled]="disabled" (change)="changeHandle($event)">
  <ng-template [ngTemplateOutlet]="triggerBlock"></ng-template>
</upload-dragger>

<ng-container *ngIf="listType === 'picture-card'">
  <ng-template [ngTemplateOutlet]="uploadList"></ng-template>
</ng-container>
<ng-container *ngIf="!drag">
  <ng-template [ngTemplateOutlet]="triggerBlock"></ng-template>
</ng-container>
<ng-container *ngIf="listType !== 'picture-card'">
  <ng-template [ngTemplateOutlet]="uploadList"></ng-template>
</ng-container>


<ul [class]="'upload-list upload-list-' + listType" [class.is-disabled]="disabled">
  <li *ngFor="let file of files; let i = index" [class]="'upload-list-item is-' + file.status" class="list-group-item d-flex justify-content-between align-items-start">
    <div class="w-100 d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <a class="upload-list-item-name" (click)="clickHandle(file)">
          <i class="feather" [class.icon-paperclip]="listType === 'text'"></i>
          {{file.name}}
        </a>
      </div>
      <div class="upload-icons d-flex align-items-center">
        <label class="upload-list-item-status-label me-6">
          <i class="feather icon-check-circle"></i>
        </label>
        <span class="upload-list-item-actions d-flex align-items-center">
          <i class="feather close icon-x me-6" *ngIf="!elDisabled" (click)="removeHandle(file)"></i>
        </span>
        <span class="upload-list-item-actions d-flex align-items-center">
          <button (click)="openModal(file.raw)" class="btn btn-primary btn-sm me-1"><i class="feather icon-eye"></i></button>
        </span>
      </div>
    </div>
  </li>
</ul>

<!----<button (click)="openModal(file.raw)" class="btn btn-primary btn-sm me-1"><i class="feather icon-eye"></i></button>-->