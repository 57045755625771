<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- Contenedor para STL -->
    <div *ngIf="isSTL" #rendererContainer class="renderer-container"></div>

    <!-- Contenedor para Imágenes -->
    <div *ngIf="isImage" class="image-container">
        <img [src]="imageSrc" class="img-fluid" alt="Image">
    </div>

    <!-- Contenedor para PDF -->
    <ngx-extended-pdf-viewer *ngIf="isPDF" [src]="pdfSrc" useBrowserLocale="true"
        height="70vh"></ngx-extended-pdf-viewer>

    <!-- Controles de Zoom -->
    <div *ngIf="isSTL" class="zoom-controls">
        <button class="btn btn-primary btn-sm" (click)="zoomOut()">+</button>
        <button class="btn btn-primary btn-sm" (click)="zoomIn()">-</button>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>